import { createSlice } from '@reduxjs/toolkit';

const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    menu: false
  },
  reducers: {
    toggleMenu: (state, action) => {
      if ( state.menu == true) {
        state.menu = false
      } else {
        state.menu = true;
      }
    },
    closeMenu: (state, action) => {
      state.menu = false
    },
  },
});

export const menuReducer = menuSlice.reducer;

export const {
  toggleMenu,
  closeMenu
} = menuSlice.actions;