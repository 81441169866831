import { motion, AnimatePresence } from 'framer-motion'
import { React, useEffect } from 'react'
import router, { useRouter } from 'next/router'

import { Provider } from 'react-redux';       // Importing Provider
import store from '../redux/store';           // Importing redux store
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import '../public/assets/css/style.scss';

import * as ga from '../lib/ga'

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  // analytics tracking
  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url)
    }
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  let persistor = persistStore(store);

  const variants = {
    hidden: { opacity: 0, x: 0, y: 0 },
    enter: { opacity: 1, x: 0, y: 0 },
    exit: { opacity: 0, x: 0, y: 0 },
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AnimatePresence exitBeforeEnter>
          <motion.div 
          className="motion-animate-wrapper" 
          key={router.route} 
          initial="hidden" 
          animate="enter" 
          exit="exit"
          transition={{ duration: 0.1 }}
          variants={variants}>
            <Component {...pageProps} />
          </motion.div>
        </AnimatePresence >
      </PersistGate>
    </Provider>
  )
}

export default MyApp
